import './LoaderStyle.css'

function Loader() {
    return (

        <div className="loader-container">
            <div className="loader" />
        </div>
    )
}

export default Loader;